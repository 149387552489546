import React, { useState } from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Navigation from "./Navigation"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header
      className="
      w-full
      fixed
      top-0
      z-10
      bg-primary
      "
    >
      <div
        className="
        relative
        border-b
        border-solid
        border-secondary
        font-body
        text-secondary
        xs:text-center
        xs:text-lg
        mb-4
        mx-2
        xs:mx-3
        py-3
      "
      >
        <AniLink
          cover
          to="/welcome"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className="
          block
          hover:text-tertiary
          foucs:text-secondary
          "
        >
          <span role="img" aria-label="Bride" className="hidden xs:inline">
            👰
          </span>{" "}
          {siteTitle}{" "}
          <span role="img" aria-label="Groom" className="hidden xs:inline">
            🤵
          </span>
        </AniLink>
        <button
          className={`navbar-toggler ${isExpanded ? `is-open` : `is-closed`}`}
          type="button"
          data-toggle="offcanvas"
          data-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <span className="sr-only">Toggle Navigation</span>
          <span className="navbar-lines"></span>
        </button>
      </div>

      <Navigation navState={isExpanded} />
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
