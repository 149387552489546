import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

function Navigation({ navState }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sa-logo-red-reverse.png" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(maxWidth: 224) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <nav
      id="navigation"
      className={`
        ${navState ? `is-open` : `is-closed translate-x-full`}
        w-full
        sm:w-2/3
        md:w-1/2
        lg:w-2/5
        min-h-full
        fixed
        top-0
        right-0
        bottom-0
        transform
        flex
        flex-col
        justify-between
        bg-secondary
        text-tertiary
        px-4
        py-12
        lg:p-16
        z-40
        transition
        transition-transform
        duration-500
        ease-in-out
        overflow-x-scroll
        
      `}
      role="navigation"
    >
      <div
        id="navigation--contact"
        className="
          flex
          justify-between
        "
      >
        <a
          href="mailto:hollyelliott@hotmail.com"
          title="Email us"
          className={`
          text-xl
          xs:mb-3
          ease-in-out
          hover:underline
          ${navState ? `opacity-100 delay-300` : `opacity-0 delay-100`}
          `}
        >
          Email us
        </a>
        <a
          href="tel:+447425145755"
          title="Text us"
          className={`
          text-xl
          mb-3
          ease-in-out
          hover:underline
          ${navState ? `opacity-100 delay-500` : `opacity-0 delay-100`}
          `}
        >
          Text us
        </a>
      </div>
      <div
        id="navigation--menu"
        className="
        flex
        flex-col
        flex-wrap
        flex-grow
        justify-center
        "
      >
        <AniLink
          cover
          to="/welcome"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className={`
          text-4xl
          my-2
          xs:my-4
          lg:text-5xl
          lg:my-8
          transform
          ease-in-out
          ${navState ? `opacity-100 delay-300` : `opacity-0 delay-100`}
          `}
          itemScope="itemscope"
          itemType="https://www.schema.org/SiteNavigationElement"
        >
          <span>Welcome</span>
        </AniLink>
        <AniLink
          cover
          to="/ceremony"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className={`
          text-4xl
          my-2
          xs:my-4
          lg:text-5xl
          lg:my-8
          transform
          ease-in-out
          ${navState ? `opacity-100 delay-500` : `opacity-0 delay-100`}
          `}
          itemScope="itemscope"
          itemType="https://www.schema.org/SiteNavigationElement"
        >
          <span>Ceremony</span>
        </AniLink>
        <AniLink
          cover
          to="/location"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className={`
          text-4xl
          my-2
          xs:my-4
          lg:text-5xl
          lg:my-8
          transform
          ease-in-out
          ${navState ? `opacity-100 delay-700` : `opacity-0 delay-100`}
          `}
          itemScope="itemscope"
          itemType="https://www.schema.org/SiteNavigationElement"
        >
          <span>Location</span>
        </AniLink>
        <AniLink
          cover
          to="/rsvp"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className={`
          text-4xl
          my-2
          xs:my-4
          lg:text-5xl
          lg:my-8
          transform
          ease-in-out
          ${navState ? `opacity-100 delay-900` : `opacity-0 delay-100`}
          `}
          itemScope="itemscope"
          itemType="https://www.schema.org/SiteNavigationElement"
        >
          <span>RSVP</span>
        </AniLink>
      </div>
      <div id="navigation--logo" className="flex">
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="w-56 ml-auto"
          alt="The Holl and Oll's Wedding Logo"
          objectFit="contain"
        />
      </div>
    </nav>
  )
}

export default Navigation
