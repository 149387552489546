/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"
import "../styles/main.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const url = typeof window !== 'undefined' ? window.location.pathname : '';

  const notGateway = (url !== '/' && url !== '') ? true : false;

  return (
    <div className="site-wrapper">

      { notGateway &&
        <Header siteTitle={data.site.siteMetadata.title} />
      }

      <main className={`${ notGateway ? `mt-main` : `sm:mt-main py-4 sm:py-0`}`}>
        {children}
      </main>
      { notGateway &&
        <Footer />
      }
    </div>
  )
}

export default Layout
