import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/pro-light-svg-icons"

const Footer = () => (
  <footer
    className="
    bg-black
    text-secondary
    pt-4
    pb-1
    sm:pt-20
    sm:pb-4
  "
  >
    <div className="container-fluid flex flex-wrap justify-between">
      <p className="w-full sm:w-auto">
        Built with <FontAwesomeIcon className="ml-1" icon={faHeart} />
      </p>

      <a
        title="Website by lawrenson.dev"
        target="_blank"
        rel="noreferrer noopener"
        href="https://lawrenson.dev"
        className="w-full sm:w-auto mb-3 hover:text-primary"
      >
        Website by lawrenson.dev
      </a>
    </div>
  </footer>
)

export default Footer
