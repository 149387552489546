import React from "react"
import favAppleTouch from "../images/favicons/apple-touch-icon.png"
import favIconL from "../images/favicons/favicon-32x32.png"
import favIconS from "../images/favicons/favicon-16x16.png"
import favSVG from "../images/favicons/safari-pinned-tab.svg"
import favManifest from "../images/favicons/site.webmanifest.json"

export default () => (
  <>
    <link rel="apple-touch-icon" sizes="180x180" href={favAppleTouch} />
    <link rel="icon" type="image/png" sizes="32x32" href={favIconL} />
    <link rel="icon" type="image/png" sizes="16x16" href={favIconS} />
    <link rel="manifest" href={favManifest} />
    <link rel="mask-icon" href={favSVG} color="#4D6D69" />
    <meta name="msapplication-TileColor" content="#4D6D69" />
    <meta name="theme-color" content="#ffffff" />
  </>
)
